export const CREATE_AMENITIES_TYPES = "CREATE_AMENITIES_TYPES";
export const CREATE_AMENITIES_TYPES_FAILURE = "CREATE_AMENITIES_TYPES_FAILURE";
export const CREATE_AMENITIES_TYPES_SUCCESS = "CREATE_AMENITIES_TYPES_SUCCESS";

export const DELETE_AMENITIES_TYPES = "DELETE_AMENITIES_TYPES";
export const DELETE_AMENITIES_TYPES_FAILURE = "DELETE_AMENITIES_TYPES_FAILURE";
export const DELETE_AMENITIES_TYPES_SUCCESS = "DELETE_AMENITIES_TYPES_SUCCESS";

export const GET_AMENITIES_TYPES = "GET_AMENITIES_TYPES";
export const GET_AMENITIES_TYPES_FAILURE = "GET_AMENITIES_TYPES_FAILURE";
export const GET_AMENITIES_TYPES_SUCCESS = "GET_AMENITIES_TYPES_SUCCESS";

export const UPDATE_AMENITIES_TYPES = "UPDATE_AMENITIES_TYPES";
export const UPDATE_AMENITIES_TYPES_FAILURE = "UPDATE_AMENITIES_TYPES_FAILURE";
export const UPDATE_AMENITIES_TYPES_SUCCESS = "UPDATE_AMENITIES_TYPES_SUCCESS";
