import *  as ActionTypes from "./contact-usActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    contactus: []
};

export const contactUsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_CONTACT_US:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_CONTACT_US_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_CONTACT_US_SUCCESS:
            return {
                ...state,
                isLoading: false,
                contactus: action?.payload?.data || []
            }
        default:
            return state

    }
}