export const CREATE_AMENITIES = "CREATE_AMENITIES";
export const CREATE_AMENITIES_FAILURE = "CREATE_AMENITIES_FAILURE";
export const CREATE_AMENITIES_SUCCESS = "CREATE_AMENITIES_SUCCESS";

export const DELETE_AMENITIES = "DELETE_AMENITIES";
export const DELETE_AMENITIES_FAILURE = "DELETE_AMENITIES_FAILURE";
export const DELETE_AMENITIES_SUCCESS = "DELETE_AMENITIES_SUCCESS";

export const GET_AMENITIES = "GET_AMENITIES";
export const GET_AMENITIES_FAILURE = "GET_AMENITIES_FAILURE";
export const GET_AMENITIES_SUCCESS = "GET_AMENITIES_SUCCESS";

export const GET_AMENITIES_FROM_RENTAL_UNITED = "GET_AMENITIES_FROM_RENTAL_UNITED";
export const GET_AMENITIES_FROM_RENTAL_UNITED_FAILURE = "GET_AMENITIES_FROM_RENTAL_UNITED_FAILURE";
export const GET_AMENITIES_FROM_RENTAL_UNITED_SUCCESS = "GET_AMENITIES_FROM_RENTAL_UNITED_SUCCESS";

export const UPDATE_AMENITIES = "UPDATE_AMENITIES";
export const UPDATE_AMENITIES_FAILURE = "UPDATE_AMENITIES_FAILURE";
export const UPDATE_AMENITIES_SUCCESS = "UPDATE_AMENITIES_SUCCESS";
