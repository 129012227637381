import *  as ActionTypes from "./roomTypeActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    roomTypes: []
};

export const roomTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_ROOM_TYPE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_ROOM_TYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_ROOM_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_ROOM_TYPE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_ROOM_TYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_ROOM_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_ROOM_TYPE:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_ROOM_TYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_ROOM_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                roomTypes: action?.payload?.data || []
            }
        case ActionTypes.UPDATE_ROOM_TYPE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_ROOM_TYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_ROOM_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state

    }
}