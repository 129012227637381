export const CREATE_LISTING_TYPE = "CREATE_LISTING_TYPE";
export const CREATE_LISTING_TYPE_FAILURE = "CREATE_LISTING_TYPE_FAILURE";
export const CREATE_LISTING_TYPE_SUCCESS = "CREATE_LISTING_TYPE_SUCCESS";

export const DELETE_LISTING_TYPE = "DELETE_LISTING_TYPE";
export const DELETE_LISTING_TYPE_FAILURE = "DELETE_LISTING_TYPE_FAILURE";
export const DELETE_LISTING_TYPE_SUCCESS = "DELETE_LISTING_TYPE_SUCCESS";

export const GET_LISTING_TYPE = "GET_LISTING_TYPE";
export const GET_LISTING_TYPE_FAILURE = "GET_LISTING_TYPE_FAILURE";
export const GET_LISTING_TYPE_SUCCESS = "GET_LISTING_TYPE_SUCCESS";

export const GET_LIST_PROPERTY_FROM_RENTAL_UNITED = "GET_LIST_PROPERTY_FROM_RENTAL_UNITED";
export const GET_LIST_PROPERTY_FROM_RENTAL_UNITED_FAILURE = "GET_LIST_PROPERTY_FROM_RENTAL_UNITED_FAILURE";
export const GET_LIST_PROPERTY_FROM_RENTAL_UNITED_SUCCESS = "GET_LIST_PROPERTY_FROM_RENTAL_UNITED_SUCCESS";

export const UPDATE_LISTING_TYPE = "UPDATE_LISTING_TYPE";
export const UPDATE_LISTING_TYPE_FAILURE = "UPDATE_LISTING_TYPE_FAILURE";
export const UPDATE_LISTING_TYPE_SUCCESS = "UPDATE_LISTING_TYPE_SUCCESS";
