export const CREATE_ROOM_AMENITIES = "CREATE_ROOM_AMENITIES";
export const CREATE_ROOM_AMENITIES_FAILURE = "CREATE_ROOM_AMENITIES_FAILURE";
export const CREATE_ROOM_AMENITIES_SUCCESS = "CREATE_ROOM_AMENITIES_SUCCESS";

export const DELETE_ROOM_AMENITIES = "DELETE_ROOM_AMENITIES";
export const DELETE_ROOM_AMENITIES_FAILURE = "DELETE_ROOM_AMENITIES_FAILURE";
export const DELETE_ROOM_AMENITIES_SUCCESS = "DELETE_ROOM_AMENITIES_SUCCESS";

export const GET_ROOM_AMENITIES = "GET_ROOM_AMENITIES";
export const GET_ROOM_AMENITIES_FAILURE = "GET_ROOM_AMENITIES_FAILURE";
export const GET_ROOM_AMENITIES_SUCCESS = "GET_ROOM_AMENITIES_SUCCESS";

export const GET_ROOM_AMENITIES_FROM_RENTAL_UNITED = "GET_ROOM_AMENITIES_FROM_RENTAL_UNITED";
export const GET_ROOM_AMENITIES_FROM_RENTAL_UNITED_FAILURE = "GET_ROOM_AMENITIES_FROM_RENTAL_UNITED_FAILURE";
export const GET_ROOM_AMENITIES_FROM_RENTAL_UNITED_SUCCESS = "GET_ROOM_AMENITIES_FROM_RENTAL_UNITED_SUCCESS";

export const UPDATE_ROOM_AMENITIES = "UPDATE_ROOM_AMENITIES";
export const UPDATE_ROOM_AMENITIES_FAILURE = "UPDATE_ROOM_AMENITIES_FAILURE";
export const UPDATE_ROOM_AMENITIES_SUCCESS = "UPDATE_ROOM_AMENITIES_SUCCESS";

export const GET_COMPOSITION_ROOM = "GET_COMPOSITION_ROOM";
export const GET_COMPOSITION_ROOM_SUCCESS = "GET_COMPOSITION_ROOM_SUCCESS";
export const GET_COMPOSITION_ROOM_FAILURE = "GET_COMPOSITION_ROOM_FAILURE";



