import *  as ActionTypes from "./roleActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    roles : []
};

export const roleReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_ROLE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_ROLE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_ROLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_ROLE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_ROLE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_ROLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_ROLE:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_ROLE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_ROLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                roles : action?.payload?.data || []
            }
        case ActionTypes.UPDATE_ROLE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_ROLE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_ROLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state

    }
}