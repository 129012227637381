import *  as ActionTypes from "./listingTypeActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    listingTypes: []
};

export const listingTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_LISTING_TYPE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_LISTING_TYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_LISTING_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_LISTING_TYPE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_LISTING_TYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_LISTING_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_LISTING_TYPE:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_LISTING_TYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_LISTING_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listingTypes: action?.payload?.data || []
            }
        case ActionTypes.GET_LIST_PROPERTY_FROM_RENTAL_UNITED:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            }
        case ActionTypes.GET_LIST_PROPERTY_FROM_RENTAL_UNITED_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_LIST_PROPERTY_FROM_RENTAL_UNITED_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            }
        case ActionTypes.UPDATE_LISTING_TYPE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_LISTING_TYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_LISTING_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state

    }
}