export const CREATE_BOOKING_STATUS = "CREATE_BOOKING_STATUS";
export const CREATE_BOOKING_STATUS_FAILURE = "CREATE_BOOKING_STATUS_FAILURE";
export const CREATE_BOOKING_STATUS_SUCCESS = "CREATE_BOOKING_STATUS_SUCCESS";

export const DELETE_BOOKING_STATUS = "DELETE_BOOKING_STATUS";
export const DELETE_BOOKING_STATUS_FAILURE = "DELETE_BOOKING_STATUS_FAILURE";
export const DELETE_BOOKING_STATUS_SUCCESS = "DELETE_BOOKING_STATUS_SUCCESS";

export const GET_BOOKING_STATUS = "GET_BOOKING_STATUS";
export const GET_BOOKING_STATUS_FAILURE = "GET_BOOKING_STATUS_FAILURE";
export const GET_BOOKING_STATUS_SUCCESS = "GET_BOOKING_STATUS_SUCCESS";

export const UPDATE_BOOKING_STATUS = "UPDATE_BOOKING_STATUS";
export const UPDATE_BOOKING_STATUS_FAILURE = "UPDATE_BOOKING_STATUS_FAILURE";
export const UPDATE_BOOKING_STATUS_SUCCESS = "UPDATE_BOOKING_STATUS_SUCCESS";
