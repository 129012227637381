export const CREATE_PROPERTY_TYPE = "CREATE_PROPERTY_TYPE";
export const CREATE_PROPERTY_TYPE_FAILURE = "CREATE_PROPERTY_TYPE_FAILURE";
export const CREATE_PROPERTY_TYPE_SUCCESS = "CREATE_PROPERTY_TYPE_SUCCESS";

export const DELETE_PROPERTY_TYPE = "DELETE_PROPERTY_TYPE";
export const DELETE_PROPERTY_TYPE_FAILURE = "DELETE_PROPERTY_TYPE_FAILURE";
export const DELETE_PROPERTY_TYPE_SUCCESS = "DELETE_PROPERTY_TYPE_SUCCESS";

export const GET_PROPERTY_TYPE = "GET_PROPERTY_TYPE";
export const GET_PROPERTY_TYPE_FAILURE = "GET_PROPERTY_TYPE_FAILURE";
export const GET_PROPERTY_TYPE_SUCCESS = "GET_PROPERTY_TYPE_SUCCESS";

export const GET_PROPERTY_TYPE_FROM_RENTAL_UNITED = "GET_PROPERTY_TYPE_FROM_RENTAL_UNITED";
export const GET_PROPERTY_TYPE_FROM_RENTAL_UNITED_FAILURE = "GET_PROPERTY_TYPE_FROM_RENTAL_UNITED_FAILURE";
export const GET_PROPERTY_TYPE_FROM_RENTAL_UNITED_SUCCESS = "GET_PROPERTY_TYPE_FROM_RENTAL_UNITED_SUCCESS";

export const UPDATE_PROPERTY_TYPE = "UPDATE_PROPERTY_TYPE";
export const UPDATE_PROPERTY_TYPE_FAILURE = "UPDATE_PROPERTY_TYPE_FAILURE";
export const UPDATE_PROPERTY_TYPE_SUCCESS = "UPDATE_PROPERTY_TYPE_SUCCESS";
