import *  as ActionTypes from "./languageActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    languages: []
};

export const languageReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_LANGUAGE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_LANGUAGE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_LANGUAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_LANGUAGE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_LANGUAGE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_LANGUAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_LANGUAGE:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_LANGUAGE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_LANGUAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                languages: action?.payload?.data || []
            }
        case ActionTypes.UPDATE_LANGUAGE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_LANGUAGE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_LANGUAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state

    }
}