import *  as ActionTypes from "./tagsActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    tags: [],
    autoTags: []
};

export const tagReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_TAGS:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_TAGS_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_TAGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_TAGS:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_TAGS_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_TAGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_TAGS:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_TAGS_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_TAGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                tags: action?.payload?.data || []
            }
        case ActionTypes.GET_AUTO_TAGS:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_AUTO_TAGS_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_AUTO_TAGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                autoTags: action?.payload?.data || []
            }
        case ActionTypes.UPDATE_TAGS:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_TAGS_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_TAGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state

    }
}