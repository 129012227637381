import Approot from "./app-root-component";
import { connect } from "react-redux";




const mapDispatchToProps = {  };

const mapStateToProps = (state) => ({
    admin: state.adminPage.loggedAdmin,
    isLoggedIn: state.adminPage.isLoggedIn,
   
});

export default connect(mapStateToProps, {})(Approot);
