import * as actionType from "./adminActionType";

const initialState = {
    isLoading: false,
    isSaved:false,
    admins:[],
    isLoggedIn: sessionStorage.getItem('loggedAdmin') !== null ? true : false,
    loggedAdmin: sessionStorage.getItem('loggedAdmin') !== null ? JSON.parse(sessionStorage.getItem('loggedAdmin')) : {}
};




export const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADMIN_LOGIN:
            return {
                ...state,
                isLoading: true,
                isLoggedIn: false
            };
        case actionType.ADMIN_LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false
            };
        case actionType.ADMIN_LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: true,
                loggedAdmin: action.payload ? action.payload.data : {},
            };
        case actionType.ADMIN_UPDATE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            };
        case actionType.ADMIN_UPDATE_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.ADMIN_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            };
        case actionType.ADMIN_REGISTER:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            };
        case actionType.ADMIN_REGISTER_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.ADMIN_REGISTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            };
        case actionType.UPDATE_ADMIN_REGISTER:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            };
        case actionType.UPDATE_ADMIN_REGISTER_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.UPDATE_ADMIN_REGISTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            };
        case actionType.GET_ADMIN_REGISTER:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            };
        case actionType.GET_ADMIN_REGISTER_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.GET_ADMIN_REGISTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                // isSaved: true,
                admins: action?.payload?.data || [],
            };
        case actionType.GET_ADMIN_CURRENT:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            };
        case actionType.GET_ADMIN_CURRENT_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.GET_ADMIN_CURRENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                loggedAdmin: action.payload ? action.payload.data : {},
            };
        default:
            return state;
    }
}