import *  as ActionTypes from "./amenities-typesActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    amenitiesTypes : []
};

export const amenitiesTypesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_AMENITIES_TYPES:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_AMENITIES_TYPES_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_AMENITIES_TYPES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_AMENITIES_TYPES:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_AMENITIES_TYPES_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_AMENITIES_TYPES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_AMENITIES_TYPES:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_AMENITIES_TYPES_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_AMENITIES_TYPES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                amenitiesTypes : action?.payload?.data || []
            }
        case ActionTypes.UPDATE_AMENITIES_TYPES:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_AMENITIES_TYPES_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_AMENITIES_TYPES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state

    }
}