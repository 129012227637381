import *  as ActionTypes from "./propertyOwnersActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    propertyOwners: []
};

export const propertyOwnerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_PROPERTY_OWNERS:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_PROPERTY_OWNERS_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_PROPERTY_OWNERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_PROPERTY_OWNERS:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_PROPERTY_OWNERS_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_PROPERTY_OWNERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_PROPERTY_OWNERS:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_PROPERTY_OWNERS_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_PROPERTY_OWNERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                propertyOwners: action?.payload?.data || []
            }
        case ActionTypes.UPDATE_PROPERTY_OWNERS:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_PROPERTY_OWNERS_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_PROPERTY_OWNERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state

    }
}