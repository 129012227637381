export const CREATE_PAGE = "CREATE_PAGE";
export const CREATE_PAGE_FAILURE = "CREATE_PAGE_FAILURE";
export const CREATE_PAGE_SUCCESS = "CREATE_PAGE_SUCCESS";

export const DELETE_PAGE = "DELETE_PAGE";
export const DELETE_PAGE_FAILURE = "DELETE_PAGE_FAILURE";
export const DELETE_PAGE_SUCCESS = "DELETE_PAGE_SUCCESS";

export const GET_PAGE = "GET_PAGE";
export const GET_PAGE_FAILURE = "GET_PAGE_FAILURE";
export const GET_PAGE_SUCCESS = "GET_PAGE_SUCCESS";

export const UPDATE_PAGE = "UPDATE_PAGE";
export const UPDATE_PAGE_FAILURE = "UPDATE_PAGE_FAILURE";
export const UPDATE_PAGE_SUCCESS = "UPDATE_PAGE_SUCCESS";
