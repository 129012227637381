export const CREATE_ROOM_TYPE = "CREATE_ROOM_TYPE";
export const CREATE_ROOM_TYPE_FAILURE = "CREATE_ROOM_TYPE_FAILURE";
export const CREATE_ROOM_TYPE_SUCCESS = "CREATE_ROOM_TYPE_SUCCESS";

export const DELETE_ROOM_TYPE = "DELETE_ROOM_TYPE";
export const DELETE_ROOM_TYPE_FAILURE = "DELETE_ROOM_TYPE_FAILURE";
export const DELETE_ROOM_TYPE_SUCCESS = "DELETE_ROOM_TYPE_SUCCESS";

export const GET_ROOM_TYPE = "GET_ROOM_TYPE";
export const GET_ROOM_TYPE_FAILURE = "GET_ROOM_TYPE_FAILURE";
export const GET_ROOM_TYPE_SUCCESS = "GET_ROOM_TYPE_SUCCESS";

export const UPDATE_ROOM_TYPE = "UPDATE_ROOM_TYPE";
export const UPDATE_ROOM_TYPE_FAILURE = "UPDATE_ROOM_TYPE_FAILURE";
export const UPDATE_ROOM_TYPE_SUCCESS = "UPDATE_ROOM_TYPE_SUCCESS";

export const GET_ROOM_TYPE_FROM_RENTAL_UNITED = "GET_ROOM_TYPE_FROM_RENTAL_UNITED";
export const GET_ROOM_TYPE_FROM_RENTAL_UNITED_FAILURE = "GET_ROOM_TYPE_FROM_RENTAL_UNITED_FAILURE";
export const GET_ROOM_TYPE_FROM_RENTAL_UNITED_SUCCESS = "GET_ROOM_TYPE_FROM_RENTAL_UNITED_SUCCESS";




