import { createTheme } from "@mui/material/styles";


const theme = createTheme({
    palette: {
        primary: {
            main: '#78716C',   //button color 
            // dark: '#000000'  // hover color
            // color={ '#131B21'}
        },
        secondary: {
            main: '#78716C', // Custom secondary color

        },

    },

    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '10px', // Set border radius
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    zIndex: 1302,
                }
            }
        },
      
    },
    typography: {


        fontFamily: [
            'Inter',
            'sans-serif',
        ].join(','),

        h5: {
            color: '#000000',
        },


        body1: {
            color: '#78716C',
        },
        span: {
            color: '#78716C',
        }
    },
});

export default theme;
