import *  as ActionTypes from "./countryActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    countries: []
};

export const countryReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_COUNTRY:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_COUNTRY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_COUNTRY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_COUNTRY:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_COUNTRY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_COUNTRY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_COUNTRY:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_COUNTRY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_COUNTRY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                countries: action?.payload?.data || []
            }
        case ActionTypes.UPDATE_COUNTRY:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_COUNTRY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_COUNTRY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state

    }
}