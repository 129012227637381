export const FETCH_BOOKING = "FETCH_BOOKING";
export const FETCH_BOOKING_FAILURE = "FETCH_BOOKING_FAILURE";
export const FETCH_BOOKING_SUCCESS = "FETCH_BOOKING_SUCCESS";

export const CANCEL_BOOKING = "CANCEL_BOOKING";
export const CANCEL_BOOKING_FAILURE = "CANCEL_BOOKING_FAILURE";
export const CANCEL_BOOKING_SUCCESS = "CANCEL_BOOKING_SUCCESS";

export const CONFIRM_BOOKING = "CONFIRM_BOOKING";
export const CONFIRM_BOOKING_FAILURE = "CONFIRM_BOOKING_FAILURE";
export const CONFIRM_BOOKING_SUCCESS = "CONFIRM_BOOKING_SUCCESS";

export const MODIFY_BOOKING = "MODIFY_BOOKING";
export const MODIFY_BOOKING_FAILURE = "MODIFY_BOOKING_FAILURE";
export const MODIFY_BOOKING_SUCCESS = "MODIFY_BOOKING_SUCCESS";

export const FETCH_RENTAL_BOOKING = "FETCH_RENTAL_BOOKING";
export const FETCH_RENTAL_BOOKING_FAILURE = "FETCH_RENTAL_BOOKING_FAILURE";
export const FETCH_RENTAL_BOOKING_SUCCESS = "FETCH_RENTAL_BOOKING_SUCCESS";

export const FETCH_CALENDAR_BOOKING = "FETCH_CALENDAR_BOOKING";
export const FETCH_CALENDAR_BOOKING_FAILURE = "FETCH_CALENDAR_BOOKING_FAILURE";
export const FETCH_CALENDAR_BOOKING_SUCCESS = "FETCH_CALENDAR_BOOKING_SUCCESS";