import *  as ActionTypes from "./customersActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    customers: []
};

export const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_CUSTOMERS:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_CUSTOMERS_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_CUSTOMERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_CUSTOMERS:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_CUSTOMERS_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_CUSTOMERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_CUSTOMERS:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_CUSTOMERS_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_CUSTOMERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                customers: action?.payload?.data || []
            }
        case ActionTypes.UPDATE_CUSTOMERS:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_CUSTOMERS_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_CUSTOMERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state

    }
}