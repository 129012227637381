import *  as ActionTypes from "./faqActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    faqs: []
};

export const faqReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_FAQ:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_FAQ_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_FAQ_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_FAQ:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_FAQ_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_FAQ_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_FAQ:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_FAQ_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_FAQ_SUCCESS:
            return {
                ...state,
                isLoading: false,
                faqs: action?.payload?.data || []
            }
        case ActionTypes.UPDATE_FAQ:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_FAQ_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_FAQ_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state

    }
}