export const CREATE_TAGS = "CREATE_TAGS";
export const CREATE_TAGS_FAILURE = "CREATE_TAGS_FAILURE";
export const CREATE_TAGS_SUCCESS = "CREATE_TAGS_SUCCESS";

export const DELETE_TAGS = "DELETE_TAGS";
export const DELETE_TAGS_FAILURE = "DELETE_TAGS_FAILURE";
export const DELETE_TAGS_SUCCESS = "DELETE_TAGS_SUCCESS";

export const GET_TAGS = "GET_TAGS";
export const GET_TAGS_FAILURE = "GET_TAGS_FAILURE";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";

export const GET_AUTO_TAGS = "GET_AUTO_TAGS";
export const GET_AUTO_TAGS_FAILURE = "GET_AUTO_TAGS_FAILURE";
export const GET_AUTO_TAGS_SUCCESS = "GET_AUTO_TAGS_SUCCESS";

export const UPDATE_TAGS = "UPDATE_TAGS";
export const UPDATE_TAGS_FAILURE = "UPDATE_TAGS_FAILURE";
export const UPDATE_TAGS_SUCCESS = "UPDATE_TAGS_SUCCESS";
