export const CREATE_STATE = "CREATE_STATE";
export const CREATE_STATE_FAILURE = "CREATE_STATE_FAILURE";
export const CREATE_STATE_SUCCESS = "CREATE_STATE_SUCCESS";

export const DELETE_STATE = "DELETE_STATE";
export const DELETE_STATE_FAILURE = "DELETE_STATE_FAILURE";
export const DELETE_STATE_SUCCESS = "DELETE_STATE_SUCCESS";

export const GET_STATE = "GET_STATE";
export const GET_STATE_FAILURE = "GET_STATE_FAILURE";
export const GET_STATE_SUCCESS = "GET_STATE_SUCCESS";

export const UPDATE_STATE = "UPDATE_STATE";
export const UPDATE_STATE_FAILURE = "UPDATE_STATE_FAILURE";
export const UPDATE_STATE_SUCCESS = "UPDATE_STATE_SUCCESS";
