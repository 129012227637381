export const CREATE_FAQ = "CREATE_FAQ";
export const CREATE_FAQ_FAILURE = "CREATE_FAQ_FAILURE";
export const CREATE_FAQ_SUCCESS = "CREATE_FAQ_SUCCESS";

export const DELETE_FAQ = "DELETE_FAQ";
export const DELETE_FAQ_FAILURE = "DELETE_FAQ_FAILURE";
export const DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS";

export const GET_FAQ = "GET_FAQ";
export const GET_FAQ_FAILURE = "GET_FAQ_FAILURE";
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";

export const UPDATE_FAQ = "UPDATE_FAQ";
export const UPDATE_FAQ_FAILURE = "UPDATE_FAQ_FAILURE";
export const UPDATE_FAQ_SUCCESS = "UPDATE_FAQ_SUCCESS";
