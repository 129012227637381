export const CREATE_RAZORPAY_SETTING = "CREATE_RAZORPAY_SETTING";
export const CREATE_RAZORPAY_SETTING_FAILURE = "CREATE_RAZORPAY_SETTING_FAILURE";
export const CREATE_RAZORPAY_SETTING_SUCCESS = "CREATE_RAZORPAY_SETTING_SUCCESS";

export const DELETE_RAZORPAY_SETTING = "DELETE_RAZORPAY_SETTING";
export const DELETE_RAZORPAY_SETTING_FAILURE = "DELETE_RAZORPAY_SETTING_FAILURE";
export const DELETE_RAZORPAY_SETTING_SUCCESS = "DELETE_RAZORPAY_SETTING_SUCCESS";

export const GET_RAZORPAY_SETTING = "GET_RAZORPAY_SETTING";
export const GET_RAZORPAY_SETTING_FAILURE = "GET_RAZORPAY_SETTING_FAILURE";
export const GET_RAZORPAY_SETTING_SUCCESS = "GET_RAZORPAY_SETTING_SUCCESS";

export const UPDATE_RAZORPAY_SETTING = "UPDATE_RAZORPAY_SETTING";
export const UPDATE_RAZORPAY_SETTING_FAILURE = "UPDATE_RAZORPAY_SETTING_FAILURE";
export const UPDATE_RAZORPAY_SETTING_SUCCESS = "UPDATE_RAZORPAY_SETTING_SUCCESS";
