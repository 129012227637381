export const CREATE_PROPERTY_OWNERS = "CREATE_PROPERTY_OWNERS";
export const CREATE_PROPERTY_OWNERS_FAILURE = "CREATE_PROPERTY_OWNERS_FAILURE";
export const CREATE_PROPERTY_OWNERS_SUCCESS = "CREATE_PROPERTY_OWNERS_SUCCESS";

export const DELETE_PROPERTY_OWNERS = "DELETE_PROPERTY_OWNERS";
export const DELETE_PROPERTY_OWNERS_FAILURE = "DELETE_PROPERTY_OWNERS_FAILURE";
export const DELETE_PROPERTY_OWNERS_SUCCESS = "DELETE_PROPERTY_OWNERS_SUCCESS";

export const GET_PROPERTY_OWNERS = "GET_PROPERTY_OWNERS";
export const GET_PROPERTY_OWNERS_FAILURE = "GET_PROPERTY_OWNERS_FAILURE";
export const GET_PROPERTY_OWNERS_SUCCESS = "GET_PROPERTY_OWNERS_SUCCESS";

export const UPDATE_PROPERTY_OWNERS = "UPDATE_PROPERTY_OWNERS";
export const UPDATE_PROPERTY_OWNERS_FAILURE = "UPDATE_PROPERTY_OWNERS_FAILURE";
export const UPDATE_PROPERTY_OWNERS_SUCCESS = "UPDATE_PROPERTY_OWNERS_SUCCESS";
