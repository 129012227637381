export const CREATE_CUSTOMERS = "CREATE_CUSTOMERS";
export const CREATE_CUSTOMERS_FAILURE = "CREATE_CUSTOMERS_FAILURE";
export const CREATE_CUSTOMERS_SUCCESS = "CREATE_CUSTOMERS_SUCCESS";

export const DELETE_CUSTOMERS = "DELETE_CUSTOMERS";
export const DELETE_CUSTOMERS_FAILURE = "DELETE_CUSTOMERS_FAILURE";
export const DELETE_CUSTOMERS_SUCCESS = "DELETE_CUSTOMERS_SUCCESS";

export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMERS_FAILURE = "GET_CUSTOMERS_FAILURE";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";

export const UPDATE_CUSTOMERS = "UPDATE_CUSTOMERS";
export const UPDATE_CUSTOMERS_FAILURE = "UPDATE_CUSTOMERS_FAILURE";
export const UPDATE_CUSTOMERS_SUCCESS = "UPDATE_CUSTOMERS_SUCCESS";
