import { combineReducers } from "redux";
import { adminReducer as adminPage } from "./../api/admin/adminReducer";
import { bookingStatusReducer as bookingStatusPage } from "./../api/bookingStatus/bookingStatusReducer";
import { countryReducer as countryPage } from "../api/country/countryReducer";
import { stateReducer as statePage } from "../api/state/stateReducer";
import { amenitiesTypesReducer as amenitiesTypePage } from "../api/amenities-types/amenities-typesReducer";
import { amenitiesReducer  as amenitiesPage } from "../api/amenities/amenitiesReducer";
import { roomAmenitiesReducer  as roomamenitiesPage } from "../api/room-amenities/roomAmenitiesReducer";
import { languageReducer as languagePage } from "../api/language/languageReducer";
import { listingTypeReducer  as listingTypePage } from "../api/listing-type/listingTypeReducer";
import { propertyTypeReducer as propertyTypePage } from "../api/property-type/propertyTypeReducer";
import { roomTypeReducer as roomTypePage } from "../api/room-type/roomTypeReducer";
import { cityReducer as cityPage } from "../api/city/cityReducer";
import { propertyOwnerReducer as propertyOwnersPage } from "../api/property-owners/propertyOwnersReducer";
import { propertyReducer  as propertyPage } from "../api/property/propertyReducer";
import { customerReducer as customerPage } from "../api/customers/customersReducer";
import { faqReducer as faqPage } from "../api/faq/faqReducer";
import { tagReducer as tagPage } from "../api/tags/tagsReducer";
import { bookingReducer as bookingPage } from "../api/booking/bookingReducer";
import { pageReducer as pagePage  } from "../api/page/pageReducer";
import { roleReducer as rolePage  } from "../api/role/roleReducer";
import {listPropertyReducer  as  listPropertyPage}  from "../api/listing-property/list-propertyReducer"
import { contactUsReducer as contactUsPage } from "../api/contact-us/contact-usReducer";
import { razorpaySettingReducer as razorpaySettingPage } from "../api/razorpay-setting/razorpaySettingReducer";
import { documentReducer  as documentPage } from "../api/document/documentReducer";




const appReducer = combineReducers({
    adminPage,
    bookingStatusPage,
    countryPage,
    statePage,
    amenitiesTypePage,
    amenitiesPage,
    roomamenitiesPage,
    languagePage,
    listingTypePage,
    propertyTypePage,
    roomTypePage,
    cityPage,
    propertyOwnersPage,
    propertyPage,
    customerPage,
    faqPage,
    tagPage,
    bookingPage,
    pagePage,
    rolePage,
    listPropertyPage,
    contactUsPage,
    razorpaySettingPage,
    documentPage
})

export default appReducer;