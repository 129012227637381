export const CREATE_LANGUAGE = "CREATE_LANGUAGE";
export const CREATE_LANGUAGE_FAILURE = "CREATE_LANGUAGE_FAILURE";
export const CREATE_LANGUAGE_SUCCESS = "CREATE_LANGUAGE_SUCCESS";

export const DELETE_LANGUAGE = "DELETE_LANGUAGE";
export const DELETE_LANGUAGE_FAILURE = "DELETE_LANGUAGE_FAILURE";
export const DELETE_LANGUAGE_SUCCESS = "DELETE_LANGUAGE_SUCCESS";

export const GET_LANGUAGE = "GET_LANGUAGE";
export const GET_LANGUAGE_FAILURE = "GET_LANGUAGE_FAILURE";
export const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS";

export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const UPDATE_LANGUAGE_FAILURE = "UPDATE_LANGUAGE_FAILURE";
export const UPDATE_LANGUAGE_SUCCESS = "UPDATE_LANGUAGE_SUCCESS";
