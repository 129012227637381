import *  as ActionTypes from "./documentActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    documents: [],
};

export const documentReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_DOCUMENT:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_DOCUMENT_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_DOCUMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                documents: action?.payload?.data || []
            }
        default:
            return state

    }
}