import *  as ActionTypes from "./roomAmenitiesActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    roomamenities: [],
    compositionRoom : []
};

export const roomAmenitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DELETE_ROOM_AMENITIES:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_ROOM_AMENITIES_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_ROOM_AMENITIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_ROOM_AMENITIES:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_ROOM_AMENITIES_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_ROOM_AMENITIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                roomamenities: action?.payload?.data || []
            }
        case ActionTypes.GET_COMPOSITION_ROOM:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_COMPOSITION_ROOM_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_COMPOSITION_ROOM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                compositionRoom: action?.payload?.data || []
            }
        case ActionTypes.GET_ROOM_AMENITIES_FROM_RENTAL_UNITED:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            }
        case ActionTypes.GET_ROOM_AMENITIES_FROM_RENTAL_UNITED_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_ROOM_AMENITIES_FROM_RENTAL_UNITED_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            }
        case ActionTypes.UPDATE_ROOM_AMENITIES:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_ROOM_AMENITIES_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_ROOM_AMENITIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state

    }
}