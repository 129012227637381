export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const ADMIN_LOGIN_FAILURE = "ADMIN_LOGIN_FAILURE";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";



export const ADMIN_UPDATE = "ADMIN_UPDATE";
export const ADMIN_UPDATE_FAILURE = "ADMIN_UPDATE_FAILURE";
export const ADMIN_UPDATE_SUCCESS = "ADMIN_UPDATE_SUCCESS";


export const ADMIN_REGISTER = "ADMIN_REGISTER";
export const ADMIN_REGISTER_FAILURE = "ADMIN_REGISTER_FAILURE";
export const ADMIN_REGISTER_SUCCESS = "ADMIN_REGISTER_SUCCESS";


export const GET_ADMIN_REGISTER = "GET_ADMIN_REGISTER";
export const GET_ADMIN_REGISTER_FAILURE = "GET_ADMIN_REGISTER_FAILURE";
export const GET_ADMIN_REGISTER_SUCCESS = "GET_ADMIN_REGISTER_SUCCESS";

export const UPDATE_ADMIN_REGISTER = "UPDATE_ADMIN_REGISTER";
export const UPDATE_ADMIN_REGISTER_FAILURE = "UPDATE_ADMIN_REGISTER_FAILURE";
export const UPDATE_ADMIN_REGISTER_SUCCESS = "UPDATE_ADMIN_REGISTER_SUCCESS";

export const DELETE_ADMIN_REGISTER = "DELETE_ADMIN_REGISTER";
export const DELETE_ADMIN_REGISTER_FAILURE = "DELETE_ADMIN_REGISTER_FAILURE";
export const DELETE_ADMIN_REGISTER_SUCCESS = "DELETE_ADMIN_REGISTER_SUCCESS";


export const GET_ADMIN_CURRENT = "GET_ADMIN_CURRENT";
export const GET_ADMIN_CURRENT_FAILURE = "GET_ADMIN_CURRENT_FAILURE";
export const GET_ADMIN_CURRENT_SUCCESS = "GET_ADMIN_CURRENT_SUCCESS";

