import React from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';



function MenuDrawer({open,anchorEl,handleClose}) {

    const navigate = useNavigate();


    const handleLogout = () => {
        sessionStorage.removeItem('adminToken');
        sessionStorage.removeItem('loggedAdmin');
        window.location.reload();
        navigate('/login')
    }

  return (
      <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          disableScrollLock
          PaperProps={{
              elevation: 0,
              sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                  },
                  '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                  },
              },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
          <MenuItem onClick={() => navigate('/profile-setting')}>
              <Avatar /> Profile
          </MenuItem>
          {/* <MenuItem onClick={handleClose}>
              <Avatar /> My account
          </MenuItem> */}
          <Divider />
          {/* <MenuItem onClick={handleClose}>
              <ListItemIcon>
                  <PersonAdd fontSize="small" />
              </ListItemIcon>
              Add another account
          </MenuItem> */}
          <MenuItem onClick={() => navigate('/update-password')}>
              <ListItemIcon>
                  <Settings fontSize="small" />
              </ListItemIcon>
              Settings
          </MenuItem>
          <MenuItem onClick={() => handleLogout()} >
              <ListItemIcon>
                  <Logout fontSize="small" />
              </ListItemIcon>
              Logout
          </MenuItem>
      </Menu>

  )
}

export default MenuDrawer