export const CREATE_ROLE = "CREATE_ROLE";
export const CREATE_ROLE_FAILURE = "CREATE_ROLE_FAILURE";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";

export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_FAILURE = "DELETE_ROLE_FAILURE";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";

export const GET_ROLE = "GET_ROLE";
export const GET_ROLE_FAILURE = "GET_ROLE_FAILURE";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";

export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_ROLE_FAILURE = "UPDATE_ROLE_FAILURE";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
