import React from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
const ProtectedPage = ({ children, isLoggedIn }) => {


    return (
        isLoggedIn ? children : <Navigate to="/login" />
    )
}

const mapStateToProps = state => ({
    isLoggedIn: state.adminPage.isLoggedIn
});

export const ProtectedRoute = connect(mapStateToProps, {})(ProtectedPage);