export const CREATE_LIST_PROPERTY = "CREATE_LIST_PROPERTY";
export const CREATE_LIST_PROPERTY_FAILURE = "CREATE_LIST_PROPERTY_FAILURE";
export const CREATE_LIST_PROPERTY_SUCCESS = "CREATE_LIST_PROPERTY_SUCCESS";

export const DELETE_LIST_PROPERTY = "DELETE_LIST_PROPERTY";
export const DELETE_LIST_PROPERTY_FAILURE = "DELETE_LIST_PROPERTY_FAILURE";
export const DELETE_LIST_PROPERTY_SUCCESS = "DELETE_LIST_PROPERTY_SUCCESS";

export const GET_LIST_PROPERTY = "GET_LIST_PROPERTY";
export const GET_LIST_PROPERTY_FAILURE = "GET_LIST_PROPERTY_FAILURE";
export const GET_LIST_PROPERTY_SUCCESS = "GET_LIST_PROPERTY_SUCCESS";

export const UPDATE_LIST_PROPERTY = "UPDATE_LIST_PROPERTY";
export const UPDATE_LIST_PROPERTY_FAILURE = "UPDATE_LIST_PROPERTY_FAILURE";
export const UPDATE_LIST_PROPERTY_SUCCESS = "UPDATE_LIST_PROPERTY_SUCCESS";
