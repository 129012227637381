import *  as ActionTypes from "./bookingStatusActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    bookingStatuses: []
};

export const bookingStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_BOOKING_STATUS:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_BOOKING_STATUS_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_BOOKING_STATUS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_BOOKING_STATUS:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_BOOKING_STATUS_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_BOOKING_STATUS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_BOOKING_STATUS:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_BOOKING_STATUS_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_BOOKING_STATUS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                bookingStatuses: action?.payload?.data || []
            }
        case ActionTypes.UPDATE_BOOKING_STATUS:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_BOOKING_STATUS_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_BOOKING_STATUS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state

    }
}