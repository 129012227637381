import *  as ActionTypes from "./propertyTypeActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    propertyTypes: []
};

export const propertyTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_PROPERTY_TYPE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_PROPERTY_TYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_PROPERTY_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_PROPERTY_TYPE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_PROPERTY_TYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_PROPERTY_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_PROPERTY_TYPE:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_PROPERTY_TYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_PROPERTY_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                propertyTypes: action?.payload?.data || []
            }
        case ActionTypes.GET_PROPERTY_TYPE_FROM_RENTAL_UNITED:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            }
        case ActionTypes.GET_PROPERTY_TYPE_FROM_RENTAL_UNITED_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_PROPERTY_TYPE_FROM_RENTAL_UNITED_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            }
        case ActionTypes.UPDATE_PROPERTY_TYPE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_PROPERTY_TYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_PROPERTY_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state

    }
}