import *  as ActionTypes from "./pageActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    pages : []
};

export const pageReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_PAGE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_PAGE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_PAGE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_PAGE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_PAGE:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_PAGE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                pages : action?.payload?.data || []
            }
        case ActionTypes.UPDATE_PAGE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_PAGE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state

    }
}