export const CREATE_COUNTRY = "CREATE_COUNTRY";
export const CREATE_COUNTRY_FAILURE = "CREATE_COUNTRY_FAILURE";
export const CREATE_COUNTRY_SUCCESS = "CREATE_COUNTRY_SUCCESS";

export const DELETE_COUNTRY = "DELETE_COUNTRY";
export const DELETE_COUNTRY_FAILURE = "DELETE_COUNTRY_FAILURE";
export const DELETE_COUNTRY_SUCCESS = "DELETE_COUNTRY_SUCCESS";

export const GET_COUNTRY = "GET_COUNTRY";
export const GET_COUNTRY_FAILURE = "GET_COUNTRY_FAILURE";
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";

export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const UPDATE_COUNTRY_FAILURE = "UPDATE_COUNTRY_FAILURE";
export const UPDATE_COUNTRY_SUCCESS = "UPDATE_COUNTRY_SUCCESS";
